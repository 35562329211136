.copyright {
  background-color: var(--color--black);
  padding: 12px 0;
}

.copyright__notice {
  color: var(--color--white);
  font-family: var(--font-family--velino-sans-book);
  font-size: var(--font-size--small);
  line-height: 20px;
  letter-spacing: 0;
}
