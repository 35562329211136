.burger {
  width: 40px;
  height: 40px;
  background: unset;
  border: unset;
  outline: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
}

.burger__slice {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  background-color: var(--color--black);
  -webkit-transition: transform 0.25s, opacity 0.25s;
  -moz-transition: transform 0.25s, opacity 0.25s;
  -o-transition: transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}

.burger__slice:nth-child(2) {
  margin: 7px 0;
}

.burger:not(.active):hover > .burger__slice:nth-child(2),
.burger:not(.active):focus-visible > .burger__slice:nth-child(2) {
  transform: translateX(-8px);
}

.burger.active > .burger__slice:nth-child(2) {
  opacity: 0;
}

.burger.active > .burger__slice:nth-child(1) {
  top: 9px;
  transform: rotate(45deg);
}

.burger.active > .burger__slice:nth-child(3) {
  top: -9px;
  transform: rotate(-45deg);
}

.burger.active:hover > .burger__slice:nth-child(1),
.burger.active:focus-visible > .burger__slice:nth-child(1) {
  transform: rotate(45deg) scale(1.2);
}

.burger.active:hover > .burger__slice:nth-child(3),
.burger.active:focus-visible > .burger__slice:nth-child(3) {
  transform: rotate(-45deg) scale(1.2);
}
