.cartItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 112px;
  padding-top: 12px;
}

.cartItem:not(:first-of-type) {
  margin-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.cartItem__image {
  object-fit: cover;
  height: 100%;
  width: auto;
  aspect-ratio: 4/3;
}

.cartItem__content {
  padding: 0 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cartItem__title {
  font: normal normal bold 20px/24px var(--font-family--velino-text-bold);
}

.cartItem__quantity {
  color: rgba(0, 0, 0, 0.2);
}

.cartItem__price {
  font-family: var(--font-family--velino-sans-medium);
  font-size: var(--font-size--regular);
  line-height: 23px;
  letter-spacing: 0;
}

.cartItem__delete {
  border: unset;
  background: unset;
  outline: unset;
  cursor: pointer;
  font: normal normal normal 22px/27px var(--font-family--velino-sans-book);
  letter-spacing: 0;
  color: var(--color--black);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--color--white);
}

.cartItem__delete:hover,
.cartItem__delete:focus-visible {
  background-color: rgba(25, 118, 210, 0.04);
}

.cartItems__empty {
  margin-top: 24px;
  text-align: center;
  font: normal normal normal 22px/27px var(--font-family--velino-sans-book);
}
