.button {
  padding: 10px 19px 9px;
  font-family: var(--font-family--velino-sans-medium);
  font-size: var(--font-size--default);
  text-transform: uppercase;
  letter-spacing: 0.8px;
  border: 1px solid var(--color--black);
  outline: unset;
  cursor: pointer;
  -webkit-transition: background-color 0.25s, color 0.25s;
  -moz-transition: background-color 0.25s, color 0.25s;
  -o-transition: background-color 0.25s, color 0.25s;
  transition: background-color 0.25s, color 0.25s;
}

.button.primary {
  background-color: var(--color--black);
  color: var(--color--white);
}

.button.secondary {
  background-color: transparent;
  color: var(--color--black);
}

.button.primary:hover,
.button.primary:focus-visible {
  background-color: transparent;
  color: var(--color--black);
}

.button.secondary:hover,
.button.secondary:focus-visible {
  background-color: var(--color--black);
  color: var(--color--white);
}

.button:disabled {
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: rgba(0, 0, 0, 0.3) !important;
  cursor: not-allowed;
}
