.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.loader__fullPage {
  height: 100vh;
}
