.fact {
  text-align: center;
}

.fact__img {
  height: 100px;
}

.fact__title,
.fact__description {
  text-align: center;
  letter-spacing: 0;
  color: var(--color--dark-grey);
}

.fact__title {
  font-family: var(--font-family--velino-text-bold);
  font-size: var(--font-size--regular);
  line-height: 23px;
  margin: 33px 0 11px;
}

.fact__description {
  font-family: var(--font-family--velino-sans-book);
  font-size: var(--font-size--default);
  line-height: 20px;
  max-width: 376px;
  margin: 0 auto;
}
