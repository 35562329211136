.productCardForm__columns {
  display: flex;
  margin: 12px -8px;
}

.productCardForm__column {
  margin: 0 8px;
}

.productCardForm__columns > .productCardForm__column:first-of-type {
  flex-shrink: 0;
  flex-basis: 102px;
}

.productCardForm__columns > .productCardForm__column:nth-of-type(2) {
  flex: 1;
}

.productCardForm .button {
  width: 100%;
}

.productCardForm__price {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family--velino-sans-medium);
  font-size: var(--font-size--regular);
  line-height: 23px;
  letter-spacing: 0;
  color: var(--color--dark-grey);
}

.ProductCardForm__validationMessageWrap {
  text-align: center;
}
