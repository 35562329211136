.footer {
  padding: 38px 0;
  background-color: var(--color--white);
}

.footer__columns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__column {
  flex-basis: 50%;
}

.footer__logo {
  cursor: pointer;
}

.footer__logo,
.footer__logo > svg {
  max-width: 137.46px;
}

@media screen and (max-width: 600px) {
  .footer__columns {
    flex-wrap: wrap;
    margin: -24px;
  }

  .footer__column {
    margin: 24px;
    flex-basis: auto;
  }
}
