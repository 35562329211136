.input {
  margin-bottom: 17px;
}

.input__component {
  width: 100%;
  padding: 16px 18px;
  border: unset;
  font-family: var(--font-family--velino-sans-book);
  font-size: var(--font-size--default);
  color: var(--color--grey);
  outline: unset;
  line-height: 16px;
  letter-spacing: 0;
}

textarea {
  resize: none;
}

.input__component::placeholder {
  color: var(--color--grey);
  opacity: 1;
}

.input__component:-ms-input-placeholder {
  color: var(--color--grey);
}

.input__component::-ms-input-placeholder {
  color: var(--color--grey);
}
