.select {
  position: relative;
}

.select > select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding-inline-start: 10.5px;
  padding-inline-end: 24px;
  padding-block: 10px;
  background-color: transparent;
  border: 1px solid var(--color--black);
  outline: unset;
  cursor: pointer;
  z-index: 1;
}

.select > select::-ms-expand {
  display: none;
}

.select__arrow {
  position: absolute;
  right: -10px;
  display: inline-block;
  font: normal normal normal 16px/19px var(--font-family--velino-sans-book);
  text-align: start;
  letter-spacing: 0;
  color: var(--color--black);
  transition: transform 0.25s;
  right: 10px;
}

.select__arrowUp {
  right: 10.5px;
  top: 40%;
  transform: translateY(-50%) rotate(90deg);
}

.select__arrowDown {
  top: 60%;
  transform: translateY(-50%) rotate(-90deg);
}
