@font-face {
  font-family: "Velino Sans Book";
  src: url(assets/fonts/velino-sans/VelinoSans-Book.otf);
  font-display: swap;
}

@font-face {
  font-family: "Velino Sans Medium";
  src: url(assets/fonts/velino-sans/VelinoSans-Medium.otf);
  font-display: swap;
}

@font-face {
  font-family: "Velino Text Book";
  src: url(assets/fonts/velino-text/VelinoText-Book.otf);
  font-display: swap;
}

@font-face {
  font-family: "Velino Text Bold";
  src: url(assets/fonts/velino-text/VelinoText-Bold.otf);
  font-display: swap;
}

:root {
  --color--black: #000000;
  --color--dark-grey: #2e2e22;
  --color--grey: #868686;
  --color--white: #ffffff;
  --color--fail: #d3302f;
  --color--success: #2e7d32;

  --font-family--velino-sans-book: "Velino Sans Book";
  --font-family--velino-sans-medium: "Velino Sans Medium";
  --font-family--velino-text-book: "Velino Text Book";
  --font-family--velino-text-bold: "Velino Text Bold";

  --font-size--small: 12px;
  --font-size--default: 16px;
  --font-size--regular: 18px;
  --font-size--medium: 20px;
  --font-size--large: 30px;
  --font-size--larger: 56px;
}

html,
body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: var(--font-size--default);
}

a {
  text-decoration: unset;
  color: unset;
}

.section-title {
  font-family: var(--font-family--velino-text-book);
  font-size: var(--font-size--large);
  font-weight: normal;
  line-height: 37px;
  letter-spacing: 0;
  text-align: center;
  position: relative;
}

.section-title-divider::before {
  content: "";
  position: absolute;
  top: -10.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 42px;
  height: 2px;
  background-color: var(--color--black);
}

.section-title-divider-start::before {
  left: 0;
  transform: unset;
}

.paragraph {
  font-family: var(--font-family--velino-sans-book);
  font-size: var(--font-size--regular);
  line-height: 24px;
  letter-spacing: 0;
}
