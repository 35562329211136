.meantToBeShared {
  padding: 70px 0 155px;
}

.meantToBeShared__description {
  max-width: 572px;
  text-align: center;
  margin: 44px auto 38px;
}

.meantToBeShared__drinksImage {
  text-align: center;
  object-fit: cover;
  width: 100%;
}

.meantToBeShared__columns {
  display: flex;
  justify-content: center;
  margin: -8px;
}

.meantToBeShared__column {
  margin: 8px;
}

@media screen and (max-width: 1023px) {
  .meantToBeShared__columns {
    flex-wrap: wrap;
  }
}
