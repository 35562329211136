.sendUsAMessageForm__loaderWrap > .loader {
  justify-content: start;
}

.sendUsAMessageForm__loaderWrap svg {
  width: 37px;
  height: 37px;
  margin: unset !important;
}

.sendUsAMessageForm__message {
  font-size: var(--font-size--small);
  text-align: center;
  margin-bottom: 12px;
}

.sendUsAMessageForm__message.fail {
  color: var(--color--fail);
}

.sendUsAMessageForm__message.success {
  color: var(--color--success);
}
