.thumbsGallery__swiper {
  width: 100%;
  height: 100%;
}

.thumbsGallery__swiperImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 9/16;
}

.thumbsGallery__swiperThumbs {
  width: 100%;
  margin-top: 16px;
  max-height: 122px;
}

.thumbsGallery__swiperThumbs .swiper-slide {
  opacity: 0.4;
  height: 100%;
  width: calc(100% / 3);
  cursor: pointer;
  object-fit: cover;
  aspect-ratio: 16/9;
  object-position: center;
}

.thumbsGallery__swiperThumbs .swiper-slide-thumb-active {
  opacity: 1;
}
