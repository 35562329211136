.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.86);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal__content {
  width: 66.9444%;
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color--white);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  -o-animation: slideToBottom 0.25s;
  -moz-animation: slideToBottom 0.25s;
  -webkit-animation: slideToBottom 0.25s;
  animation: slideToBottom 0.25s;
}

@-webkit-keyframes slideToBottom {
  from {
    top: -150vh;
  }
  to {
    top: 0;
  }
}

@keyframes slideToBottom {
  from {
    top: -150vh;
  }
  to {
    top: 0;
  }
}

@media screen and (max-width: 768px) {
  .modal__content {
    width: calc(100vw - 48px);
  }
}

.modal__close {
  border: unset;
  background: unset;
  outline: unset;
  cursor: pointer;
  font: normal normal normal 30px/37px var(--font-family--velino-sans-book);
  letter-spacing: 0;
  color: var(--color--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: auto;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  right: 20px;
  top: 20px;
}
