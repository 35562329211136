.recommendation {
  margin-top: 120px;
}

.recommendation__title {
  font: normal normal normal 30px/48px var(--font-family--velino-text-book);
  letter-spacing: 0;
  margin-bottom: 25.5px;
}

.recommendation__columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -25px -8px;
}

.recommendation__column {
  margin: 25px 8px;
  flex-basis: calc(25% - 16px);
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .recommendation__column {
    flex-basis: calc(50% - 16px);
  }
}
