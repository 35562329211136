.mocktailsCollection {
  padding: 70px 0;
}

.mocktailsCollection__title {
  margin-bottom: 55px;
}

.mocktailsCollection__columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -25px -8px;
}

.mocktailsCollection__column {
  margin: 25px 8px;
  flex-basis: calc((100% / 3) - 16px);
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .mocktailsCollection__column {
    flex-basis: calc(50% - 16px);
  }
}

@media screen and (max-width: 665px) {
  .mocktailsCollection__column {
    flex: auto;
  }
}
