.slider__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider {
  width: 100%;
  height: 100%;
}

.slider > .swiper-wrapper {
  height: calc(100% - 58px);
}

.slider > .swiper-pagination {
  bottom: 20px;
}

.slider > .swiper-pagination > .swiper-pagination-bullet-active {
  background-color: var(--color--black);
}
