.ourStory {
  position: relative;
  padding: 73px 0;
}

.ourStory__paragraph {
  max-width: 600px;
  text-align: center;
  margin: 55px auto 0;
  white-space: pre-line;
}

.ourStory__image1,
.ourStory__image2,
.ourStory__image3 {
  position: absolute;
}

.ourStory__image1 {
  top: -50px;
  left: -50px;
}

.ourStory__image1 img {
  object-position: bottom;
}

.ourStory__image2 {
  top: 0px;
  right: -40px;
}

.ourStory__image2 img {
  transform: scale(1.01);
}

.ourStory__image3 {
  bottom: 0px;
  left: 123px;
}

.ourStory__title,
.ourStory__paragraph {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1216px) {
  .ourStory__paragraph {
    max-width: 768px;
  }
}

@media screen and (max-width: 1023px) {
  .ourStory > .hide-under-desktop {
    display: none;
  }
}
