.header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 44.55px 10%;
  z-index: 20;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.header.scrolled {
  background-color: var(--color--white);
  box-shadow: 0 -2px 10px rgb(0 0 0 / 20%);
}

.header__logo {
  cursor: pointer;
  margin-inline-end: auto;
}

.header__cartWrap {
  margin-inline-start: 15px;
}

.header__cartWrap .modal__content {
  max-width: 402px;
}

@media screen and (max-width: 1023px) {
  .header > .headerMenu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    margin: 0;
    background: var(--color--white);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header.active > .headerMenu {
    display: flex;
  }

  .header .headerMenu__hyperlink {
    width: 100%;
    margin: 0;
    padding: 15px 0;
    text-align: center;
  }

  .header__logo {
    z-index: 20;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding-block: 22px;
  }

  .header__logo > .logo > img {
    max-width: 137.46px;
  }
}

@media screen and (min-width: 1024px) {
  .header > .burger {
    display: none;
  }
}
