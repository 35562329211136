.productCard__imageWrap {
  position: relative;
  cursor: pointer;
}

.productCard__imageWrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.productCard__imageWrap:hover::before,
.productCard__imageWrap:focus-visible::before {
  opacity: 1;
}

.productCard__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/5;
}

.productCard__title,
.productCard__description {
  text-align: center;
  letter-spacing: 0;
  color: var(--color--dark-grey);
}

.productCard__title {
  font: normal normal bold 20px/24px var(--font-family--velino-text-bold);
  margin: 24px 0 11px;
}

.productCard__description {
  font: normal normal normal 18px/22px var(--font-family--velino-sans-book);
  margin-bottom: 12px;
}

.productCard__actionButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
}

.productCard__actionButtons > button {
  width: 100%;
  max-width: clamp(200px, 100%, 50%);
  margin: 0 auto;
}
