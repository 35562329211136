.aboveTheFold__columns {
  display: flex;
}

.aboveTheFold__column {
  width: 50%;
}

.aboveTheFold__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 190px 10% 0;
}

.aboveTheFold__title,
.aboveTheFold__subtitle {
  color: var(--color--black);
  letter-spacing: 0;
}

.aboveTheFold__title {
  font-family: var(--font-family--velino-text-book);
  font-size: var(--font-size--larger);
  font-weight: normal;
  line-height: 67px;
}

.aboveTheFold__subtitle {
  font-family: var(--font-family--velino-sans-book);
  font-size: var(--font-size--medium);
  line-height: 28px;
  margin: 44px 0;
}

.aboveTheFold__sliderWrap > .slider {
  max-height: 100vh;
}

.aboveTheFold__sliderWrap .slider__image {
  aspect-ratio: 9/16;
}

@media screen and (max-width: 1023px) {
  .aboveTheFold__columns {
    flex-direction: column;
  }

  .aboveTheFold__column {
    width: 100%;
  }

  .aboveTheFold__content {
    padding-top: 190px;
    margin-bottom: 44px;
    text-align: center;
    align-items: center;
  }

  .aboveTheFold__sliderWrap {
    margin-bottom: 64px;
  }

  .aboveTheFold__sliderWrap > .slider {
    max-height: unset;
    overflow: visible;
  }

  .aboveTheFold__sliderWrap > .slider > .swiper-pagination {
    bottom: -30px;
  }

  .aboveTheFold__sliderWrap .slider__image {
    max-height: 50vh;
    aspect-ratio: 16/9;
  }
}

@media screen and (max-width: 768px) {
  .aboveTheFold__content {
    padding-top: 145px;
  }
}
