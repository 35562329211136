.firstPurchasePopup__success {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 24px;
  font-family: var(--font-family--velino-sans-book);
}

.firstPurchasePopup__success > h1 {
  font-size: var(--font-size--larger);
}

.firstPurchasePopup__columns {
  display: flex;
  height: 100%;
}

.firstPurchasePopup__column {
  width: 50%;
  overflow: hidden;
}

.firstPurchasePopup__backgroundImg {
  background-size: cover;
}

.firstPurchasePopup__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 50px;
}

.firstPurchasePopup__content > h1 {
  font: normal normal normal 40px/50px var(--font-family--velino-text-book);
  letter-spacing: 0;
  margin-bottom: 46px;
  text-align: justify;
}

.firstPurchasePopup__content > p {
  font: normal normal normal 18px/23px var(--font-family--velino-sans-book);
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 63px;
}

.firstPurchasePopup__labelWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.firstPurchasePopup__labelWrap > label {
  color: var(--color--dark-grey);
  cursor: pointer;
  font-family: var(--font-family--velino-text-book);
}

.firstPurchasePopup__label {
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
  margin-inline-end: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.firstPurchasePopup__label > input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.firstPurchasePopup__checkMark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  outline: 1px solid var(--color--dark-grey);
  outline-offset: 3px;
  background-color: unset;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.firstPurchasePopup__label > input:checked ~ .firstPurchasePopup__checkMark {
  background-color: var(--color--dark-grey);
}

@media screen and (max-width: 1023px) {
  .firstPurchasePopup__content {
    padding-inline: 16px;
  }

  .firstPurchasePopup__content > h1 {
    font-size: 30px;
    line-height: 37px;
  }
}

@media screen and (max-width: 768px) {
  .firstPurchasePopup__columns {
    flex-direction: column;
  }

  .firstPurchasePopup__backgroundImg {
    height: 30%;
    background-position: center;
  }

  .firstPurchasePopup__content {
    height: 70%;
  }

  .firstPurchasePopup__column {
    width: 100%;
  }
}
