.number__input::-webkit-outer-spin-button,
.number__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number__input[type="number"] {
  -moz-appearance: textfield;
}

.number {
  padding: 8px 17.5px;
  border: 1px solid var(--color--black);
  background-color: unset;
  font: normal normal normal 16px/19px var(--font-family--velino-sans-book);
  color: var(--color--black);
  text-align: center;
}

.number__input {
  font: inherit;
  border: unset;
  background: unset;
  padding: unset;
  text-align: center;
  width: 49px;
  outline: unset;
  cursor: default;
}

.number__button {
  font: inherit;
  background: unset;
  outline: unset;
  border: unset;
  cursor: pointer;
}
