.headerMenu__hyperlink {
  font-family: var(--font-family--velino-sans-book);
  font-size: var(--font-size--default);
  line-height: 19px;
  color: var(--color--black);
  text-transform: uppercase;
  margin: 0 15px;
}

.headerMenu {
  margin: 0 -15px;
}
