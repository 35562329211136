.footerMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.footerMenu__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerMenu__link {
  text-decoration: underline;
  text-transform: uppercase;
}

.footerMenu__link,
.footerMenu__contact,
.footerMenu__follow {
  color: var(--color--black);
  font-family: var(--font-family--velino-sans-medium);
  font-size: var(--font-size--default);
  line-height: 19px;
  letter-spacing: 0;
}

.footerMenu__link:not(:first-of-type),
.footerMenu__contact,
.footerMenu__follow {
  margin-top: 13px;
}

.footerMenu__follow {
  display: flex;
  align-items: center;
}

.footerMenu__socialIcon {
  margin-inline-end: 6px;
}

.footerMenu__socialIcon > svg {
  width: 11.07px;
  height: 11.07px;
}

@media screen and (max-width: 1023px) {
  .footerMenu {
    flex-wrap: wrap;
  }
}
