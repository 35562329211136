.sendUsAMessage {
  padding-bottom: 73px;
}

.sendUsAMessage__columns {
  display: flex;
}

.sendUsAMessage__column {
  flex-basis: 50%;
}

.sendUsAMessage__firstColumn {
  position: relative;
}

.sendUsAMessage__image1 img {
  object-position: right;
  transform: scale(1.01);
}

.sendUsAMessage__image2 {
  position: absolute;
  top: 250px;
  left: 150px;
}

.sendUsAMessage__image2 img {
  object-position: -56px;
}

.sendUsAMessage__title {
  text-align: start !important;
}

.sendUsAMessage__description {
  margin: 27px 0 34px;
}

@media screen and (max-width: 1123px) {
  .sendUsAMessage__columns {
    flex-wrap: wrap;
  }

  .sendUsAMessage__column {
    flex-basis: 100%;
  }

  .sendUsAMessage__firstColumn {
    margin-bottom: 155px;
  }
}
