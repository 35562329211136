.howToServe {
  width: 100%;
}

.howToServe__iconsWrap,
.howToServe__titlesWrap {
  display: flex;
  align-items: baseline;
  gap: 12px;
}

.howToServe__title,
.howToServe__icon {
  width: 25%;
  text-align: center;
}

.howToServe__title {
  font: normal normal normal 14px/18px var(--font-family--velino-sans-book);
  white-space: pre-line;
}

.howToServe__icon > img {
  width: 35px;
  object-fit: cover;
}

.howToServe__circles {
  position: relative;
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  gap: 12px;
  width: 100%;
  margin: 10px 0 18px;
}

.howToServe__circles::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: var(--color--black);
}

.howToServe__circle {
  width: 24px;
  height: 24px;
  background-color: var(--color--black);
  color: var(--color--white);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
