.cart {
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background: unset;
  outline: unset;
  cursor: pointer;
  width: 37px;
  padding: 8px;
  border-radius: 100%;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.cart:hover,
.cart:focus-visible {
  background-color: rgba(0, 0, 0, 0.1);
}

.cart__content {
  padding: 24px;
}

.cart__title {
  font-family: var(--font-family--velino-text-book);
  font-size: var(--font-size--large);
  font-weight: normal;
  line-height: 37px;
  letter-spacing: 0;
}
