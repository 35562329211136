.circularImage {
  overflow: hidden;
  border-radius: 100%;
}

.circularImage > img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
