.firstPurchaseForm {
  display: flex;
  align-items: flex-start;
}

.firstPurchaseForm > .input {
  flex: 1;
}

.firstPurchaseForm .input__component {
  height: 54px;
  border: 1px solid var(--color--black);
  border-right: unset;
}

.firstPurchaseForm > .button {
  width: 145px;
  height: 54px;
}

.firstPurchaseForm__message {
  font-size: var(--font-size--small) !important;
  text-align: center;
  margin-bottom: 12px !important;
}

.firstPurchaseForm__message.fail {
  color: var(--color--fail);
}

.firstPurchaseForm__message.success {
  color: var(--color--success);
}
